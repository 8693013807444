export function isString(value: any) {
  if (typeof value === 'string') {
    return true;
  }
  if (typeof value !== 'object') {
    return false;
  }

  return Object.prototype.toString.call(value) === '[object String]';
}

export function firstLetterToLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function firstLetterToUpperCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
